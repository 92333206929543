import React from "react"

const beautifulphotos = () => {
  return (
    <section className="exclusive">
      <div className="container pt-4">
        <div className="row">
          <div className="col-12 col-lg-12 text-center">
            <p className="services-digital">
              Get your brand in the limelight with beautiful visual content!
            </p>
            <p className="story-text">
              We have a wonderful team of photographers and photo stylists to
              ensure that your products and your brand is portrayed in the most
              impressive way. We capture just the right photos for your business
              that can be used on your website or as social media content. Video
              content is also one of the most influential and efficient way to
              get your brand message across. Right from creating fun and
              engaging video content to documenting business progress and
              activities, we have it all covered for you!
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default beautifulphotos
