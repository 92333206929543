import React from "react"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import box from "../../../images/svg/menu.svg"
import Right from "../../../images/svg/left-arrow.svg"
import Left from "../../../images/svg/right-arrows.svg"

const photosservices = () => {
  return (
    <>
      <section className="exclusive">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4">
              <h1 className="page-title exclusive-graphic">Photo Shoots</h1>
              <ul className="service-graphic">
                <li>Fashion </li>
                <li>Protuct Shoot</li>
                <li>Food </li>
                <li>Event </li>
                <li>Wedding</li>
                <li>travel </li>
                <li>Advertising </li>
                <li>coporate </li>
                <li>Architectural </li>
                <li>Industrial </li>
                <li>Real estates</li>
                <li>Sports </li>
                <li>Social media</li>
              </ul>
            </div>
            <div className="col-12 col-lg-2 d-none d-lg-block"></div>
            <div className="col-12 col-lg-4 d-none d-lg-block">
              <h1 className="page-title exclusive-graphic">Video Shoots</h1>
              <ul className="service-graphic">
                <li>Ad Film</li>
                <li>Corporate Film</li>
                <li>2D / 3D Animated Film</li>
                <li>Products &amp; Models</li>
                <li>Product Demo</li>
                <li>Video Presentation</li>
                <li>Walk-through Animation</li>
                <li>Explainer Video</li>
                <li>Conference Filming</li>
                <li>Training Video</li>
                <li>Social Media Video</li>
                <li>Video Marketing</li>
              </ul>
            </div>
            <div className="col-12 col-lg-12 text-center page-next py-4">
              <div className="d-flex align-items-center justify-content-center">
              <Link to="/services/graphic-design">
                {" "}
                <img src={Right} alt="" className="navigation-left"></img>{" "}
                <span>
                Graphic Design
                </span>
             
              </Link>
              <img src={box} className="navigation-btn" alt="" ></img>
              <Link to="/services/website-mobile-app-development">
                {" "}
                <span>
                Website &amp; Mobile Application Development
                </span>
               
                <img src={Left} alt="" className="navigation-right"></img>     
              </Link>
              </div>
             
            </div>
       
          </div>
        </div>
      </section>
    </>
  )
}

export default photosservices
