import React from "react"

const photos = () => {
  return (
    <section>
      <div className="container pt-4">
        <div className="row">
          <div className="col-12 col-lg-12 text-center">
            <h4 className="heading fw-bold about-heading">
              Photography &amp; Videography
            </h4>
            <p className="story-text">
              We strive to differentiate your brand from the competition by
              understanding your unique requirements. Our artwork abode is
              capable of providing solutions to any stage of the designing
              process right from generating ideas, increasing sales to boosting
              traffic on the virtual storefronts.
            </p>
            <p className="about-digital pt-4">
              Artistic and engaging graphic designs with a touch of empathy
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default photos
