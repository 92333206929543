import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PhotographyPage from "../../components/services/photography-videography/index"
const photographyPage = () => {
  return (
    <Layout>
      <Seo
        title="Photography &amp; Videography in chennai, Bangalore - Mass Media Creatives"
        desc="We strive to differentiate your brand from the competition by understanding your unique requirements"
        pathname=""
        keywords="Photo Shoots in chennai, video Shoots in chennai, Photography &amp; Videography in chennai, Photography &amp; Videography in bangalore"
      />
      <PhotographyPage />
    </Layout>
  )
}

export default photographyPage
